import React, { useState, useCallback } from 'react';
import coret from '../assets/Corethics.png';
import ball from '../assets/ball.png';
import kopi from '../assets/kopi.png';
import sl from '../assets/slwebsite.png';
import Box from '@material-ui/core/Box';
import { layoutGenerator } from 'react-break';

const layout = layoutGenerator({
  mobile: 0,
  tablet: 768,
  desktop: 992,
});
 
const OnMobile = layout.is('mobile');
const OnAtLeastTablet = layout.isAtLeast('tablet');
const OnDesktop = layout.is('desktop');





const Work = () => {


  return (
<>


    <OnMobile>
    <div name='work' className='w-full md:h-screen text-gray-300 bg-[#000000]'  style={{borderBottom: '10px dotted white'}}>

<p className='text-4xl font-bold inline' style={{ position: 'absolute', top: '8%', left: '5%'}}>
  W o r k
</p>


<div>
<Box style={{position: 'relative', left: '68%', top: '30vh', backgroundColor: 'black', width: '10vh', height: '10vh', color: 'white'}}> TAP TO REVEAL</Box>
</div>

{/* Container */}
<div className='grid sm:grid-cols-8 md:grid-cols-2 gap-4'>

  {/* Grid Item */}
<div
  style={{ borderRadius: '200px',width: '25vh', height: '25vh', position: 'relative', top: '50%', left: '-25%', backgroundColor: '#F5DADF'}}
  className=' group container rounded-md flex justify-center items-center mx-auto content-div'
>
   <p style={{color: 'black', position: 'relative', left: '10px'}}> </p>
  {/* Hover Effects */}
  <div className='opacity-0 group-hover:opacity-100'>
  </div>
</div>
<div
  style={{ borderRadius: '200px',width: '25vh', height: '25vh',  top: '30%', position: 'relative', left: '20%', backgroundColor: '#F5DADF' }}
  className='s group container rounded-md flex justify-center items-center mx-auto content-div1'
>
   <p style={{color: 'black', position: 'relative', left: '10px'}}> </p>
</div>

<div
  style={{ borderRadius: '200px',width: '25vh', height: '25vh',  top: '5%', position: 'relative', left: '-25%', backgroundColor: '#F5DADF' }}
  className=' group container rounded-md flex justify-center items-center mx-auto content-div2'
>
  <p style={{color: 'black', position: 'relative', left: '10px'}}> </p>
  
</div>

<div>
<Box style={{position: 'relative', left: '54%', top: '-100%', backgroundColor: 'black', width: '40%', height: '10vh', color: 'white', fontStyle: 'italic'}}> More coming soon..</Box>
</div>

<a className='flex justify-between items-center w-full text-gray-300' href="https://keira-recommend.webflow.io" target="_blank" rel="noreferrer" 
>

<button style={{ color: 'white', top: '-180%', position: 'relative', left: '54%', backgroundColor: 'black', borderRadius: '10px', padding: '8px', border: 'white 1px solid'}}> Another Example</button>
</a>

</div>

</div>


    </OnMobile>
    
    <OnAtLeastTablet> 
    
    <div name='work' className='w-full md:h-screen text-gray-300 bg-[#000000]'  style={{height: '100vh', borderBottom: '10px dotted white'}}>
    
          <p className='text-4xl font-bold' style={{color: 'white', position: 'absolute', top: '10vh', left: '5%'}}>
            W o r k
          </p>
          <p className='py-6'></p>
    
    
    {/* Container */}
        <div className='grid sm:grid-cols-8 md:grid-cols-2 gap-4'>
    
            {/* Grid Item */}
            <p style={{ width: '15%', height: '15%', position: 'absolute', top: '30%', left: '15%'}}></p>
          <div
            style={{ borderRadius: '200px',width: '30vh', height: '30vh', position: 'absolute', top: '30%', left: '5%', backgroundColor: '#F5DADF'}}
            className='group container rounded-md flex justify-center items-center mx-auto content-div'
          >
             <p style={{color: 'black', width: '20vh', position: 'relative', left: '10px' }}>Animation in HTML, JS and CSS.  </p>
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100'>
              <span className='text-2xl font-bold text-white tracking-wider'>
              </span>
              <div className='pt-8 text-center'>
              </div>
            </div>
          </div>
          <div
            style={{ borderRadius: '200px',width: '30vh', height: '30vh', top: '60%', position: 'absolute', left: '36%', backgroundColor: '#F5DADF' }}
            className=' group container rounded-md flex justify-center items-center mx-auto content-div1'
          >
             <p style={{color: 'black',  width: '20vh',position: 'relative', left: '10px'}}> Custom code website designs</p>
          </div>
    
          <div
            style={{ borderRadius: '200px',width: '30vh', height: '30vh', top: '30%', position: 'absolute', left: '68%', backgroundColor: '#F5DADF' }}
            className=' group container rounded-md flex justify-center items-center mx-auto content-div2'
          >
            <p style={{color: 'black', width: '20vh', position: 'relative', left: '10px'}}>Existing Frameworks including Webflow, Wordpress and Squarespace.  </p>
            
          </div>
          <a className='flex justify-between items-center w-full text-gray-300' href="https://keira-recommend.webflow.io" target="_blank" rel="noreferrer" 
    >
    
          <button style={{ color: 'white', top: '86%', position: 'absolute', left: '80%', backgroundColor: 'black', borderRadius: '10px', padding: '10px', border: 'white solid 1px'}}> Another Example Site</button>
          </a>
          <div>
          <Box style={{position: 'absolute', left: '80%', top: '75%', backgroundColor: 'black', width: '10%', height: '6vh', color: 'white', fontStyle: 'italic'}}> More coming soon..</Box>
        </div>
        <div
            style={{ borderRadius: '200px',width: '20vh', height: '20vh', position: 'absolute', top: '30%', left: '40%', backgroundColor: '#F5DADF'}}
            className='group container rounded-md flex justify-center items-center mx-auto'
          >
             <p style={{color: 'black', width: '12vh', position: 'relative', left: 'px'}}>Moblie first responsiveness</p>
        </div>
        <div
            style={{ borderRadius: '200px',width: '10vh', height: '10vh', position: 'absolute', top: '70%', left: '10%', backgroundColor: '#F5DADF'}}
            className='group container rounded-md flex justify-center items-center mx-auto'
          >
            
        </div>
        <div
            style={{ borderRadius: '200px',width: '15vh', height: '15vh', position: 'absolute', top: '10%', left: '30%', backgroundColor: '#F5DADF'}}
            className='group container rounded-md flex justify-center items-center mx-auto'
          >
             <p style={{color: 'black', width: '10vh', position: 'relative', left: '10%' }}>Hover on circles </p>

        </div>
        <div
            style={{ borderRadius: '200px',width: '15vh', height: '15vh', position: 'absolute', top: '70%', left: '67%', backgroundColor: '#F5DADF'}}
            className='group container rounded-md flex justify-center items-center mx-auto'
          >
             <p style={{color: 'black', width: '12vh', position: 'relative', left: '17px' }}>Website upgrades </p>

        </div>
        <div
            style={{ borderRadius: '200px',width: '15vh', height: '15vh', position: 'absolute', top: '15%', left: '56%', backgroundColor: '#F5DADF'}}
            className='group container rounded-md flex justify-center items-center mx-auto'
          >
             <p style={{color: 'black', width: '10vh', position: 'relative', left: '10px' }}> Improve SEO </p>

        </div>

        <div
            style={{ borderRadius: '200px',width: '5vh', height: '5vh', position: 'absolute', top: '20%', left: '90%', backgroundColor: '#F5DADF'}}
            className='group container rounded-md flex justify-center items-center mx-auto'
          >
             <p style={{color: 'black', width: '10vh', position: 'relative', left: '25px' }}> </p>

        </div>
        </div>
      </div>
    
        
    
    </OnAtLeastTablet>
 
</>
    
  );
};

export default Work;
