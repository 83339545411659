import React from 'react';
import { layoutGenerator } from 'react-break';

const layout = layoutGenerator({
  mobile: 0,
  tablet: 768,
  desktop: 992,
});
 
const OnMobile = layout.is('mobile');
const OnAtLeastTablet = layout.isAtLeast('tablet');
const OnDesktop = layout.is('desktop');

const About = () => {
  return (
    <>
    <OnAtLeastTablet>
    <div name='about' className='w-full h-screen bg-[#000000] text-gray-300'  style={{ borderBottom: '10px dotted white'}}>
      <div className='flex flex-col justify-center items-center w-full h-full'>
       
          <div className='sm:text-right pb-8 pl-4'> 
            <p className='text-4xl font-bold inline ' style={{ position: 'absolute', left: '20%', top: '10%'}}>
              A b o u t
            </p>
         
          <div style={{width: '80%'}}>
              <div className="mycanvas"></div>
              <div className="container"></div>
              <div className="ball"></div>
              <div className="ball1"></div>
              <div className="ball2"></div>
              <div className="ball3"></div>
          </div>
          </div>
            <div style={{ height: '30vh', width: '5vh'}}>
              <p className='text-2xl' style={{ position: 'absolute', left: '20%', top: '20%', height: '60%', width: '70%'}}>
              An Australian-based web developer and designer with experience in front-end engineering. Providing comprehensive consultancy in achieving outcomes that reflect the client's vision of a comprehensive and integrated web portfolio.  Qualifications in Computer Science and Neuroscience enable myself to design the web page with a unique understanding and support the ongoing consultation process. Specialising in interactive web design in the front-end space.
</p>
    
        </div>
      </div>
    </div>
    </OnAtLeastTablet>
    <OnMobile>
    <div name='about' className='w-full h-screen bg-[#000000] text-gray-300'  style={{ borderBottom: '10px dotted white'}}>
            <p className='text-4xl inline' style={{ position: 'relative', left: '7%', top: '10%'}}>
              A b o u t
            </p>
              <p className='text-xl' style={{ position: 'relative', left: '7%', top: '10%', height: '50%', width: '90%'}}>
              
An Australian-based web developer and designer with experience in front-end engineering. Providing comprehensive consultancy in achieving outcomes that reflect the client's vision of a comprehensive and integrated web portfolio.  Qualifications in Computer Science and Neuroscience enable myself to design the web page with a unique understanding and support the ongoing consultation process. I specialise in interactive web design in the front-end space.
<p> •	Custom design consultative approach</p>
<p> 
•	Supportive ongoing technical expertise
</p>
<p> 
•	Mobile first design capabilities
</p>
<p> 
•	Not-for-profit website experience
</p>
              </p> 
         
      </div>
    </OnMobile>
    </>
  );
};

export default About;
