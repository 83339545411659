import React from 'react';
import three from '../assets/3dim.png';
import Box from '@material-ui/core/Box';
import cat from '../assets/Sweet-2.png';
import { layoutGenerator } from 'react-break';



const layout = layoutGenerator({
  mobile: 0,
  tablet: 768,
  desktop: 992,
});
 
const OnMobile = layout.is('mobile');
const OnAtLeastTablet = layout.isAtLeast('tablet');
const OnDesktop = layout.is('desktop');

const Home = () => {
  return (
    <>
    <OnAtLeastTablet>
    <div name='home' className='w-full h-screen bg-[#000000]'>
      {/* Container */}
      <div className='max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full'>
        <img src={cat} style={{ width: '10%', height: '20%', top: '0%', position: 'absolute', left: '2%'}}
          >
          </img>
         
        <div>
        <iframe src='https://my.spline.design/coloredcompositioncopycopy-b8190c279c52f8058fad6aa4cb431588/' frameborder='0' width='100%' height='600vh'></iframe>
        <div className="wrapper">
    <div className="typing-demo">
      Welcome to NEW Mocca 
    </div>
</div>

<div className="wrapper1">
    <div className="typing-demo1">
      A portfolio site
    </div>
</div>
      
       </div>
        <div>
          <Box style={{position: 'absolute', left: '75%', top: '80%', backgroundColor: 'black', width: '20%', height: '10vh', color: 'white', fontStyle:'italic'}}>
            </Box>
            <Box style={{position: 'absolute', left: '75%', top: '85%', backgroundColor: 'black', width: '20%', height: '10vh', color: 'white', fontStyle:'italic'}}>
            <p style={{position: 'absolute', left: '5%', top: '5%'}}></p>
            </Box>
         
        </div>
      </div>
    </div>
    </OnAtLeastTablet>

    <OnMobile>
    <div name='home' className='w-full h-screen bg-[#000000]'>
   
      {/* Container */}
      <div className='max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full'>
        <img src={cat} style={{ width: '25%', height: '15%', top: '0%', position: 'absolute', left: '2%'}}
          >
          </img>
         
        <div>
      
        <iframe src='https://my.spline.design/coloredcompositioncopycopy-b8190c279c52f8058fad6aa4cb431588/' frameborder='0' width='100%' height='460vh'></iframe>
        <div className="wrapper">
    <div className="typing-demo">
      Welcome to NEW Mocca 
    </div>
</div>
        </div>
        <div>
        <Box style={{position: 'absolute', left: '75%', top: '75%', backgroundColor: 'black', width: '20%', height: '10vh', color: 'white', fontStyle:'italic'}}>
            </Box>
          
        </div>
      </div>
    </div>
    </OnMobile>

  
    </>
  );
};

export default Home;
