import React, { useState } from 'react';
import {
  FaBars,
  FaTimes,
  FaGithub,
  FaLinkedin,
} from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import { BsFillPersonLinesFill } from 'react-icons/bs';
import Logo from '../assets/logo.png';
import { Link } from 'react-scroll';
import Box from '@material-ui/core/Box';


const Navbar = ({nav, handleClick}) => {
  
 
 {/* const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  */}

  return (
    <div className='fixed w-full h-[80px] flex justify-between items-center px-4 bg-[#00000] text-gray-300'>
      <div>
        
      </div>

      <ul className='hidden md:flex'>
      <a href='/'>
        <Box className='bg-[#000000] text-white hover:bg-[#F5DADF] hover:border-pink-500'style={{ padding:'8px', border: '1px solid white' , borderRadius: '25px'}}>
        <li>
        Home
        </li>
        </Box>
        </a>
      <a href='/about'>
        <Box className='bg-[#000000] text-white hover:bg-[#F5DADF] hover:border-pink-500'style={{ padding:'8px', border: '1px solid white' , borderRadius: '25px', marginLeft: '8px'}}>
        <li>
        About
        </li>
        </Box>
        </a>
        <a href='/work'>
        <Box className='bg-[#000000]text-white hover:bg-[#F5DADF] hover:border-green-500'style={{padding:'8px',border: '1px solid white' , borderRadius: '25px', marginLeft: '8px'}}>
        <li>
       Work
        </li>
        </Box>
        </a>
        <a href='/contact'>
        <Box className='bg-[#000000] text-white hover:bg-[#F5DADF] hover:border-blue-500'style={{padding:'8px',border: '1px solid white' , borderRadius: '25px', marginLeft: '8px'}}>
        <li>
        Contact
        </li>
        </Box>
        </a>
      </ul>

    
      <div onClick={handleClick} className='md:hidden z-10'>
        {!nav ? <FaBars /> : <FaTimes />}
      </div>

      
      <ul
        className={
          !nav
            ? 'hidden'
            : 'absolute top-0 left-0 w-full h-screen bg-[#000000] flex flex-col justify-center items-center'
        }
      >
      <Box style={{position: "relative", top: '10px'}}>
      <li className='py-4 text-4xl' style={{position: "relative", left: '10px'}}>
        <a href='/'>Home</a>
        </li>
        <li className='py-4 text-4xl' style={{position: "relative", left: '10px'}}>
        <a href='/about'>About</a>
        </li>
        <li className='py-6 text-4xl' style={{position: "relative", left: '15px'}}>
        <a href='/work'>Work</a>
        </li>

        <li className='py-6 text-4xl' style={{position: "relative"}}>
        <a href='/contact'>Contact</a>
        </li>
        </Box>

    </ul>
    </div>
  );
};

export default Navbar;
