import About from "./components/About";
import Contact from "./components/Contact";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Work from "./components/Work";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {Routes, Route} from 'react-router-dom';
import React, {useState} from 'react';



const firebaseConfig = {
  apiKey: "AIzaSyD1KQQQkYyjnu_vFGa51hbp1gUyB4dbOAY",
  authDomain: "react-a06ae.firebaseapp.com",
  projectId: "react-a06ae",
  storageBucket: "react-a06ae.appspot.com",
  messagingSenderId: "824835683251",
  appId: "1:824835683251:web:712075f7c6e339b63711c6",
  measurementId: "G-NH3QH8Y3HW"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  return (
    <>
      <Navbar handleClick={handleClick} nav={nav}/>
      {!nav ? <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/About' element={<About/>} />
        <Route path='/Work' element={<Work/>} />
        <Route path='/Contact' element={<Contact/>} />
      </Routes> : <></> }
    </>
  );
}


export default App;
