import React from 'react'
import { FaGithub, FaInbox,  FaLinkedin,} from "react-icons/fa";
import { layoutGenerator } from 'react-break';

 
const layout = layoutGenerator({
  mobile: 0,
  tablet: 991,
  desktop: 992,
});
 
const OnMobile = layout.is('mobile');
const OnAtLeastTablet = layout.isAtLeast('tablet');
const OnDesktop = layout.is('desktop');
 
const Contact = () => (
  <div>
    <OnMobile>
    <div name='work' className='w-full md:h-screen text-gray-300 bg-[#000000]'  style={{borderBottom: '10px dotted white'}}>
    <div name='contact' className='w-full h-screen bg-[#000000] flex p-6' >
    

  <h1 style={{color: 'white',position: 'absolute', fontSize: '40px', fontStyle: 'bold',top: '10%', left: '15%'}}> C o n t a c t </h1>
    <p style={{color: 'white', fontSize: '30px',position: 'absolute', left: '15%', top: '25%', width: '60%'}}>Feel free to send message if you would like to collaborate on a project or work with me.</p>
  
  
   
       <button style={{color: 'white', position: 'absolute', left: '10%', top: '90%'}} > <a href="https://www.linkedin.com" target="_blank" rel="noreferrer">  <FaInbox size={40}/>  </a></button>
       <button style={{color: 'white', position: 'absolute', left: '45%', top: '90%'}} > <a href="https://www.linkedin.com" target="_blank" rel="noreferrer">  <FaLinkedin size={40}/> </a> </button>
  
    <button style={{color: 'white', position: 'absolute', left: '80%', top: '90%'}} > <a href="https://www.linkedin.com" target="_blank" rel="noreferrer">  <FaGithub size={40}/> </a> </button>


</div>
  
</div>
    </OnMobile>
 
 
    <OnAtLeastTablet>
    <div name='contact' className='w-full h-screen bg-[#000000] flex' >
    <div name='contact' className='w-full h-screen bg-[#000000] flex justify-center items-center p-4'>
<div>
  <h1 className='text-4xl font-bold inline text-gray-300' style={{position: 'absolute',
    top: '10%', left: '15%'}}> C o n t a c t </h1>
    <p className='text-4xl' style={{display: 'flex', justifyContent: 'space-around', color: 'white', position: 'absolute', left: '2%', top: '30%', width: '50%', marginLeft: '.5rem'}}>Feel free to send message </p>
    <p className='text-4xl' style={{display: 'flex', justifyContent: 'space-around', color: 'white', position: 'absolute', left: '-1%', top: '40%', width: '50%', marginLeft: '.5rem'}}> if you would like to</p>
    <p className='text-4xl' style={{display: 'flex', justifyContent: 'space-around', color: 'white', position: 'absolute', left: '1%', top: '50%', width: '50%', marginLeft: '.5rem'}}> collaborate on a project</p>
    <p className='text-4xl' style={{display: 'flex', justifyContent: 'space-around', color: 'white', position: 'absolute', left: '-2%', top: '60%', width: '50%', marginLeft: '.5rem'}}> or work with me.</p>
  
  <div className='text-3xl' style={{color: 'white', position: 'absolute', left: '14%', top: '85%'}}> 
  <a className='flex justify-between items-center w-full text-gray-300'
 href="https://www.linkedin.com/in/keira-liddle-70b049141/" target="_blank" rel="noreferrer"
  
    >
      <p style={{padding: '20px', fontSize: '40px'}}>Linkedin</p>
      <FaLinkedin size={30} />
    </a>
    </div> 
  <div className='text-3xl' style={{color: 'white', position: 'absolute', left: '42%', top: '85%'}}><a className='flex justify-between items-center w-full text-gray-300'
      href="mailto:keira.liddle22@gmail.com" target="_blank" rel="noreferrer"
    >
      <p style={{padding: '20px', fontSize: '40px'}}>Email</p> <FaInbox size={30}  />
    </a></div>
  <div className='text-3xl' style={{color: 'white', position: 'absolute', left: '70%', top: '85%'}}>
    <a className='flex justify-between items-center w-full text-gray-300'
      href='/'
    >
      <p style={{padding: '20px', fontSize: '40px'}}>Github</p> <FaGithub size={30} />
    </a></div>
  </div>
</div>

         
    </div>



    </OnAtLeastTablet>
  </div>
);
export default Contact;